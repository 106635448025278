$(document).on('click', '#send', function (e) {
    var btn = $(this),
        form = btn.parents('form'),
        popup = form.parents('.popup'),
        inputs = form.find('.required'),
        reset_inputs = form.find('.inputs')

    inputs.each(inputCheck)
    e.preventDefault()

    if (!form.find('.error').length) {
        var user_name = $('#user_name').val(),
            phone = $.trim(form.find('#phone')[0].mask.unmaskedValue),
            message = $('#message').val();
            prod_name = '',
            email = '';

        results = {
            user_name,
            phone,
            message,
            prod_name,
            email
        };

        btn.attr('disabled', true)

        sendAjax('add_request_popup', results, function (res) {
            btn.attr('disabled', false)
            reset_inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup)
        }, function (res) {
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})

$(document).on('click', '#send-form', function (e) {
    var btn = $(this),
        form = btn.parents('form'),
        popup = form.parents('.popup'),
        inputs = form.find('.required'),
        reset_inputs = form.find('.inputs')

    inputs.each(inputCheck)
    e.preventDefault()

    if (!form.find('.error').length) {
        var user_name = $('#name-1').val(),
            phone = $.trim(form.find('#phone-1')[0].mask.unmaskedValue),
            prod_name = '',
            message = '',
            email = '';

        results = {
            user_name,
            phone,
            message,
            prod_name,
            email
        };

        btn.attr('disabled', true)

        sendAjax('add_request_popup', results, function (res) {
            btn.attr('disabled', false)
            reset_inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup)
        }, function (res) {
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})


$(document).on('click', '#services-popup', function (e) {
    var btn = $(this),
        form = btn.parents('form'),
        popup = form.parents('.popup'),
        inputs = form.find('.required'),
        reset_inputs = form.find('.inputs')

    inputs.each(inputCheck)
    e.preventDefault()

    if (!form.find('.error').length) {

        var user_name = $('#services-name').val(),
            phone = $.trim(form.find('#services-phone')[0].mask.unmaskedValue),
            message = $('#services-message').val(),
            prod_name = $('.product-name').text(),
            email = '';

        results = {
            user_name,
            phone,
            message,
            prod_name,
            email
        };

        btn.attr('disabled', true)

        sendAjax('add_request_popup', results, function (res) {
            btn.attr('disabled', false)
            reset_inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup)
        }, function (res) {
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})

$(document).on('click', '#contacts-form', function (e) {
    var btn = $(this),
        form = btn.parents('form'),
        popup = form.parents('.popup'),
        inputs = form.find('.required'),
        reset_inputs = form.find('.inputs')

    inputs.each(inputCheck)
    e.preventDefault()

    if (!form.find('.error').length) {

        var user_name = $('#contacts-name').val(),
            phone = $.trim(form.find('#contacts-phone')[0].mask.unmaskedValue),
            message = $('#contacts-message').val(),
            prod_name = '',
            email = $('#contacts-email').val();

        results = {
            user_name,
            phone,
            message,
            prod_name,
            email
        };

        btn.attr('disabled', true)

        sendAjax('add_request_popup', results, function (res) {
            btn.attr('disabled', false)
            reset_inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup)
        }, function (res) {
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})  