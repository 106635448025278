function headerScroll() {
    if ($(window).scrollTop()) {
        $('header.site-header').addClass('scroll')
        
    } else {
        $('header.site-header').removeClass('scroll')
    }
}

headerScroll()


$(window).scroll(headerScroll);

$(document).on('click', '.open-menu', function () {
    $('.mobile-menu').addClass('active')
    $('body').addClass('overflow-hidden')
})

$(document).on('click', '.close-menu', function () {
    $('.mobile-menu').removeClass('active')
    $('body').removeClass('overflow-hidden')
})

$(document).click(function (e) {
    var menu = $('.menu-group')

    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
        $('.mobile-menu').removeClass('show')
    }
})



$(function () {

    $(window).scroll(function () {

        if ($(this).scrollTop() != 0) {

            $('#toTop').fadeIn();

        } else {

            $('#toTop').fadeOut();

        }

    });

    $('#toTop').click(function () {

        $('body,html').animate({ scrollTop: 0 }, 800);

    });

});
