function openPopup(popup, video) {
    if (typeof (popup) == 'string') {
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeIn(400)
}

function closePopup(popup) {
    if (typeof (popup) == 'string') {
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeOut(400)
}
$('.order-btn').click(function () {
    openPopup('order-popup')
})

$('.services-btn').click(function () {
    openPopup('services-popup')
})

$(document).on('click', '.close-popup', function(){
    var elem = $(this),
        popup = elem.parents('.popup'),
        btn = popup.find('.btn')

    closePopup(popup)
    btn.removeAttr('disabled')
})