$(document).ready(function () {
    $(".reviews-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 1500,
        prevArrow: '<div class="arrows prev icon-arrow-left"></div>',
        nextArrow: '<div class="arrows next icon-arrow-right"></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false
                }
            }
        ]
    });
});

$(document).ready(function () {
    $(".category-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1500,
        prevArrow: '<div class="arrows prev icon-arrow-left"></div>',
        nextArrow: '<div class="arrows next icon-arrow-right"></div>',
    });
});

$(document).ready(function () {
    $(".single-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1500,
        prevArrow: '<div class="arrows prev icon-arrow-left"></div>',
        nextArrow: '<div class="arrows next icon-arrow-right"></div>',
    });
});

$(document).ready(function () {
    $(".news-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false
                }
            }
        ],
    });
});
